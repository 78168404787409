import { render, staticRenderFns } from "./DroneCard.vue?vue&type=template&id=6abb8222&scoped=true"
import script from "./DroneCard.vue?vue&type=script&lang=js"
export * from "./DroneCard.vue?vue&type=script&lang=js"
import style0 from "./DroneCard.vue?vue&type=style&index=0&id=6abb8222&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6abb8222",
  null
  
)

/* custom blocks */
import block0 from "@i18n/drone/drone_model.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdrone%2FDroneCard.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@i18n/drone/drone_lot.th.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdrone%2FDroneCard.vue&locale=th&lang=yaml&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@i18n/drone/drone.th.yaml?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fdrone%2FDroneCard.vue&locale=th&lang=yaml&external"
if (typeof block2 === 'function') block2(component)
import block3 from "./DroneCard.vue?vue&type=custom&index=3&blockType=i18n&locale=th&lang=yaml"
if (typeof block3 === 'function') block3(component)

export default component.exports
<i18n locale="th" lang="yaml" >
page.title : "Ticket : {name}"
page.description : "แก้ไขข้อมูลของ Ticket"

ticket_update.confirm.title : "ยืนยันการแก้ไข Ticket"
ticket_update.confirm.message : "คุณต้องการยืนยันการแก้ไข Ticket {ticket} ตัวนี้ ?"
ticket_update.success : "ทำการแก้ไข Ticket {ticket} เรียบร้อย"
ticket_update.success.no_changed : "Ticket {ticket} ไม่ได้มีการเปลี่ยนค่า"
ticket_update.update.label : "แก้ไข Ticket"
</i18n>
<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: ticket.ticketNumber })"
			:description="$t('page.description')" />

		<div :class="containerCss">
			<div class="mylayout-left">
				<a-card :bordered="false">
					<TicketForm
						ref="formRef"
						:loading="loading"
						mode="update"
						@submit="handleSubmit">
						<template v-slot:submitSlot="{ formLoading }">
							<a-button
								:disabled="formLoading"
								icon="left"
								size="large"
								:style="{ 'margin-right': '4px' }"
								@click="goBack">
								{{$t('common.back')}}
							</a-button>
							<a-button
								:loading="formLoading"
								type="info"
								html-type="submit"
								class="btn-submit"
								icon="edit"
								size="large">
								{{$t('ticket_update.update.label')}}
							</a-button>
						</template>
					</TicketForm>
				</a-card>
			</div>
			<div v-if="!isGeneralTicket" class="mylayout-right">
				<DroneCard
					v-if="ticket.droneData && ticket.droneServiceDroneInstanceId"
					class="sticky"
					:drone="ticket.droneData.drone"
					:lot="ticket.droneData.lot"
					:model="ticket.droneData.model"
					:owner="ticket.droneData.owner"
					:firmware="ticket.droneData.firmware"
					:connect="ticket.droneData.connect"/>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import TicketForm from "@components/ticket/TicketForm.vue"
import DroneCard from "@components/drone/DroneCard.vue"
import axios from "axios"
export default {
	components : {
		TicketForm , DroneCard ,
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.ticket.ticket_no})
		}
	} ,
	data() {
		return {
			ticket : {} ,
			loading : false,
		}
	} ,
	computed : {
		isGeneralTicket() {
			return this.ticket.id && this.ticket.id > 0 && !(this.ticket.drone_id && this.ticket.drone_id > 0)
		} ,
		containerCss() {
			return this.isGeneralTicket ? "" : "mylayout-right-left responsive"
		}
	} ,
	mounted() {
		this.fetchData();
	} ,
	methods : {
		fetchData() {
			const ticketId = this.$route.params.id;
			if (!ticketId)
				return
			this.showPageLoading()
			this.clearBreadcrumbParams('ticketNo')
			axios.get("/api/tickets/"+ticketId).then((response) => {
				this.ticket = response.data.data.ticket
				this.$refs.formRef.updateTicketForm(this.ticket)

				this.addBreadcrumbParams({ticketNo : this.ticket.ticketNumber})
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('ticket_update.confirm.title') ,
				content : this.$t('ticket_update.confirm.message',{"ticket" : this.ticket.ticketNumber}) ,
				maskClosable : true,
				onOk: () => {
					this.loading = true

					axios.post('/api/tickets/'+this.ticket.id+'/update',formData).then((response)=>{
						const changed = response.data.data.changed;
						if (changed) {
							this.$message.success(this.$t('ticket_update.success',{"ticket" : this.ticket.ticketNumber}))
						} else {
							this.$message.success(this.$t('ticket_update.success.no_changed',{"ticket" : this.ticket.ticketNumber}))
						}
						this.goBack();
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=> {
						this.loading = false
					})
				}
			});
		} ,
		goBack() {
			this.$router.push({name:'ticket/view',params : {id : this.ticket.id}})
		}
	}
}
</script>
